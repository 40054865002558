<template>
  <div
    class="animated fadeIn"
    :class="'appModal ' + (isOpen ? 'appModal-open' : '')"
  >
    <div
      class="appModal__close animated bounceInUp"
      style="z-index: 999"
      @click="close"
    />
    <div class="appModal__content animated bounceInUp">
      <div
        class="appModal__close__content"
        @click="close"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
      //                this.$el.querySelector('.appModal__content').classList.remove('zoomInUp');
      //                this.$el.querySelector('.appModal__content').classList.add('zoomOutUp');
    },
  },
};
</script>

<style lang="scss">
.appModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  min-height: 10vh;
  overflow: auto;
  background-color: rgba(black, 0.2);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    max-height: 88vh;
    width: 770px;
    background-color: white;
    border-radius: 50px;
    padding: 70px;
    box-sizing: border-box;
    overflow: auto;
  }

  &__close,
  &__close__content {
    position: relative;
    top: 20px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #ff4f79;
    background-image: url("../assets/images/cancel.png");
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: center;
    z-index: 0;
    cursor: pointer;

    &__content {
      display: none;
    }
  }

  &-open {
    display: flex;
    z-index: 4;
  }
}
.appModal__content::-webkit-scrollbar {
  border-radius: 50px;
  width: 10px;
  background-color: transparent;
  left: -20px;
}

.appModal__content::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  border-radius: 100px;
}

@media screen and (max-width: 850px) {
  .appModal__content::-webkit-scrollbar {
    display: none;
  }

  .appModal {
    z-index: 5;

    &__content {
      max-height: 100vh;
      width: 100vw;
      height: 100vh;
      border-radius: 0px;
      padding: 120px 20px;
    }

    &__close {
      display: none;

      &__content {
        display: block;
        top: -60px;
        left: 50%;
        transform: translateX(-20px);
      }
    }
  }
}

@media screen and (max-height: 650px) {
  .appModal {
    &__content {
      overflow-y: scroll;
    }
  }
}
</style>

<template>
  <button
    :class="'addButton addButton-' + color"
    @click="$emit('click')"
  >
    <div
      v-if="spinning == false"
      class="addButton__plus animated rotateIn"
    >
      <img
        style="animation: zoom 1s infinite;"
        src="../assets/images/addButton_plus.png"
      >
    </div>
    <div
      v-else-if="spinning == true"
      class="addButton__plus"
    >
      <img
        src="../assets/images/buttonSpinner.svg"
        width="100%"
        alt="Spinner"
      >
    </div>
  </button>
</template>

<script>
//import axios from 'axios';
//import config from '@/config';
//import {mapGetters} from 'vuex';
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
    spin: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spinning: false,
    };
  },
  watch: {
    "$store.state.miniMissions"() {
      this.spinning = this.spin;
      if (this.$store.state.miniMissions.length == 0) {
        setTimeout(() => {
          this.spinning = false;
          //this.color = 'red';
        }, 1000);
      }
    },
  },
  mounted() {
    //            var instance = axios.create({
    //                baseURL: config.API_HOST + '/missions'
    //            });
    //            instance.interceptors.request.use(function () {
    //                this.spin = true;
    //                if (this.$store.state.miniMissions.length == 0) {
    //                    setTimeout(() => {
    //                        this.spin = false;
    //                        //this.color = 'red';
    //                    }, 1000)
    //                }
    //            });
    //            axios.interceptors.request.use((config) => {
    //                this.spin = true;
    //                return config;
    //            }, (error) => {
    //                this.spin = false;
    //                return Promise.reject(error);
    //            });
    //
    //            axios.interceptors.response.use((response) => {
    //                this.spin = false;
    //                return response;
    //            }, (error) => {
    //                this.spin = false;
    //                return Promise.reject(error);
    //            });
  },
};
</script>

<style lang="scss" scoped>
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 30px;
  height: 70px;
  min-height: 70px;
  width: 70px;
  outline: none;
  border: none;
  border-radius: 100%;
  background-color: #e5e5e5;
  color: white;
  cursor: pointer;
  font: {
    size: 20px;
  }

  &-red {
    background-color: #ff4f79;
    box-shadow: 0px 4px 20px rgba(255, 79, 121, 0.5);
  }

  &-blue {
    background-color: #36d5f2;
    box-shadow: 0px 4px 10px rgba(54, 213, 242, 0.5);
    // background: linear-gradient(181.4deg, #2ccbe8 35.62%, #6cf9ff 103.95%);
    // border: 2px solid #36d5f2 !important;
    // box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.5) !important;
  }

  &-yellow {
    background-color: #ffd44f;
    box-shadow: 0px 4px 20px rgba(255, 212, 79, 0.5);
  }

  &-green {
    background-color: #badc58;
    box-shadow: 0px 4px 20px rgba(255, 79, 121, 0.5);
  }
}

.addButton__plus {
  img {
    display: block;
    // margin: auto 0;
    height: 30px;
    width: 30px;
  }
}
</style>

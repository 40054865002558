<template>
  <div class="addBalance">
    <template v-if="euro == 0">
      <div class="addBalance__box wizardBalance">
        <span class="addBalance__box__title">Your balance</span>
        <!-- <span class="addBalance__box__minimum">£30.00 minimum needed</span> -->
        <span class="addBalance__box__money">£{{ euro }},00</span>
        <AddButton
          class="addBalance__box__btn"
          color="red"
          @click="$emit('click')"
        />
      </div>
    </template>
    <template v-else>
      <div class="addBalance__box__active">
        <span class="addBalance__box__title__active">Your balance</span>
        <span class="addBalance__box__money__active">£{{ euro }},00</span>
        <AddButton
          class="addBalance__box__btn__active"
          color="red"
          @click="$emit('click')"
        />
      </div>
    </template>
  </div>
</template>
<script>
import AddButton from "./AddButton";
export default {
  components: {
    AddButton,
  },
  props: {
    euro: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.addBalance__box {
  width: 387px;
  // height: 387px;
  height: 300px;
  background: #ffd44f;
  box-sizing: border-box;
  border-radius: 40px;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
#wizardBalance .wizardBalance {
  width: 100% !important;
  height: auto;
  padding: 40px;
  margin-top: 0;
  .addBalance__box__btn {
    margin: 30px auto auto auto;
  }
}

#wizardBalance .addBalance__box__active {
  width: 100% !important;
  height: auto !important;
  padding: 40px;
  .addBalance__box__btn__active {
    margin: 30px auto auto auto !important;
  }
}

.addBalance__box__title__active,
.addBalance__box__title {
  // margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #7a615a;
}
.addBalance__box__minimum {
  margin-top: 15px;
  // font-family: Roboto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-align: center;
  color: #ff4f79;
}
.addBalance__box__money__active,
.addBalance__box__money {
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  line-height: 67px;
  text-align: center;
  color: #7a615a;
  // margin-top: 50px;
}
.addBalance__box__btn {
  width: 100px;
  height: 100px;
  margin-left: 144px;
  // margin-top: 60px;
  margin-top: 10px;
}
.addBalance__box__active {
  width: 387px;
  height: 387px;
  background: #ffd44f;
  box-sizing: border-box;
  border-radius: 40px;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.addBalance__box__btn__active {
  width: 100px;
  height: 100px;
  margin-left: 144px;
  margin-top: 95px;
}

@media screen and (max-width: 950px) {
  .addBalance__box {
    height: auto;
    width: 100%;
  }

  .addBalance__box__btn {
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .addBalance__box__active {
    height: auto;
    width: 100%;
  }

  .addBalance__box__btn__active {
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 950px) {
  .addBalance__box__active {
    border-radius: 20px;
  }
  .addBalance__box__title__active,
  .addBalance__box__money__active,
  .addBalance__box__btn__active {
    margin: 0;
  }
  .addBalance__box__active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
  .addBalance__box__title {
    margin-top: 0;
  }
  .addBalance__box {
    padding: 20px 0;
  }
  .addBalance__box__money {
    margin-top: 10px;
  }
  .addBalance__box {
    border-radius: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .addBalance__box__money__active,
  .addBalance__box__money {
    font-size: 35px;
  }
}
</style>

<template>
  <div class="setupMain__content">
    <AppModalPayment
      :is-open="modalPayment"
      @close="closeModalPayment"
    >
      <AppFormPayment
        title="Payment successful"
        description="Your payment has been successfully processed."
        thanks="Thank you!!"
      />
    </AppModalPayment>

    <div class="setupMain__title fade-in">
      Add some rewards to fuel those missions!
    </div>
    <div class="setupMain__boxes slide-in-bottom smallContainer">
      <!-- <AddAdress @click="openModalAddress" /> -->

      <AddBalance
        id="wizardBalance"
        :euro="$store.state.balance"
        class="AddBalance"
        style="margin-top: 20px;"
        @click="openBalanceModal"
      />

      <!-- <AppModal
        title="Add an address"
        description="Enter your address details"
        style="overflow: inherit !important;"
        :is-open="balanceModal"
        @close="closeBalanceModal"
      >
        <AppInput
          style="display: none;"
          v-model="euro"
          type="number"
          required
          placeholder="Add money to your balance"
        />

        <div class="card-list" style="margin-top: -130px;">
          <div class="card-item" v-bind:class="{ '-active': isCardFlipped }">
            <div class="card-item__side -front">
              <div
                class="card-item__focus"
                v-bind:class="{ '-active': focusElementStyle }"
                v-bind:style="focusElementStyle"
                ref="focusElement"
              ></div>
              <div class="card-item__cover">
                <img
                  v-bind:src="'https://i.postimg.cc/d0QYbYC1/card-1.jpg'"
                  class="card-item__bg"
                />
              </div>

              <div class="card-item__wrapper">
                <div class="card-item__top">
                  <img
                    src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                    class="card-item__chip"
                  />
                  <div class="card-item__type">
                    <transition name="slide-fade-up">
                      <img
                        v-bind:src="
                          'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                            getCardType +
                            '.png'
                        "
                        v-if="getCardType"
                        v-bind:key="getCardType"
                        alt=""
                        class="card-item__typeImg"
                      />
                    </transition>
                  </div>
                </div>
                <label
                  for="cardNumber"
                  class="card-item__number"
                  ref="cardNumber"
                >
                  <template v-if="getCardType === 'amex'">
                    <span v-for="(n, $index) in amexCardMask" :key="$index">
                      <transition name="slide-fade-up">
                        <div
                          class="card-item__numberItem"
                          v-if="
                            $index > 600 &&
                              $index < 700 &&
                              cardNumber.length > $index &&
                              n.trim() !== ''
                          "
                        >
                          *
                        </div>

                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          :key="$index"
                          v-else-if="cardNumber.length > $index"
                        >
                          {{ cardNumber[$index] }}
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          v-else
                          :key="$index + 1"
                        >
                          {{ n }}
                        </div>
                      </transition>
                    </span>
                  </template>

                  <template v-else>
                    <span v-for="(n, $index) in otherCardMask" :key="$index">
                      <transition name="slide-fade-up">
                        <div
                          class="card-item__numberItem"
                          v-if="
                            $index > 600 &&
                              $index < 700 &&
                              cardNumber.length > $index &&
                              n.trim() !== ''
                          "
                        >
                          *
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          :key="$index"
                          v-else-if="cardNumber.length > $index"
                        >
                          {{ cardNumber[$index] }}
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          v-else
                          :key="$index + 1"
                        >
                          {{ n }}
                        </div>
                      </transition>
                    </span>
                  </template>
                </label>
                
      <div class="card-item__content">
                  <label for="cardName" class="card-item__info" ref="cardName">
                    <div class="card-item__holder">Card Holder</div>
                    <transition name="slide-fade-up">
                      <div
                        class="card-item__name"
                        v-if="cardName.length"
                        key="1"
                      >
                        <transition-group name="slide-fade-right">
                          <span
                            class="card-item__nameItem"
                            v-for="(n, $index) in cardName.replace(
                              /\s\s+/g,
                              ' '
                            )"
                            v-if="$index === $index"
                            v-bind:key="$index + 1"
                            >{{ n }}</span
                          >
                        </transition-group>
                      </div>
                      <div class="card-item__name" v-else key="2">
                        Full Name
                      </div>
                    </transition>
                  </label>
                  <div class="card-item__date" ref="cardDate">
                    <label for="cardMonth" class="card-item__dateTitle"
                      >Expires</label
                    >
                    <label for="cardMonth" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="cardMonth" v-bind:key="cardMonth">{{
                          cardMonth
                        }}</span>
                        <span v-else key="2">MM</span>
                      </transition>
                    </label>
                    /
                    <label for="cardYear" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="cardYear" v-bind:key="cardYear">{{
                          String(cardYear).slice(2, 4)
                        }}</span>
                        <span v-else key="2">YY</span>
                      </transition>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-item__side -back">
              <div class="card-item__cover">
                <img
                  v-bind:src="'https://i.postimg.cc/KzK2J5Xv/card-back.jpg'"
                  class="card-item__bg"
                />
              </div>
              <div class="card-item__band"></div>
              <div class="card-item__cvv">
                <div class="card-item__cvvTitle">CVV</div>
                <div class="card-item__cvvBand">
                  <span v-for="(n, $index) in cardCvv" :key="$index">
                    *
                  </span>
                </div>
                <div class="card-item__type">
                  <img
                    v-bind:src="
                      'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                        getCardType +
                        '.png'
                    "
                    v-if="getCardType"
                    class="card-item__typeImg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrapper">
          <div class="card-form">
            <AppForm
              @submit="addFunds"
              title="Add funds"
              description="Enter your credit card details"
            >
              <div
                class="card-form__inner"
                style="padding-top: 20px !important;"
              >
                <div class="card-input">
                  <input
                    type="text"
                    id="cardNumber"
                    maxlength="19"
                    class="card-input__input"
                    v-model="cardNumber"
                    placeholder="Card Number"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardNumber"
                    autocomplete="off"
                  />
                </div>
                <div class="card-input">
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    v-model="cardName"
                    placeholder="Card Holder"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardName"
                    autocomplete="off"
                  />
                </div>
                <div class="card-form__row">
                  <div class="card-form__col">
                    <div class="card-form__group">
                      <label for="cardMonth" class="card-input__label"
                        >Expiration Date</label
                      >
                      <select
                        class="card-input__input -select"
                        id="cardMonth"
                        v-model="cardMonth"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate"
                      >
                        <option value="" disabled selected>Month</option>
                        <option
                          v-bind:value="n < 10 ? '0' + n : n"
                          v-for="n in 12"
                          v-bind:disabled="n < minCardMonth"
                          v-bind:key="n"
                        >
                          {{ n > 10 ? n : n }}
                        </option>
                      </select>
                      <select
                        class="card-input__input -select"
                        id="cardYear"
                        v-model="cardYear"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate"
                      >
                        <option value="" disabled selected>Year</option>
                        <option
                          v-bind:value="$index + minCardYear"
                          v-for="(n, $index) in 12"
                          v-bind:key="n"
                        >
                          {{ $index + minCardYear }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="card-form__col -cvv">
                    <div class="card-input">
                      <label for="cardCvv" class="card-input__label">CVV</label>
                      <input
                        type="text"
                        class="card-input__input"
                        id="cardCvv"
                        maxlength="4"
                        v-model="cardCvv"
                        v-on:focus="flipCard(true)"
                        v-on:blur="flipCard(false)"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <AppInput
                  v-model="amount"
                  type="number"
                  required
                  placeholder="Add money to your balance"
                />
                <AppButton
                  style="margin-bottom: 30px;"
                  class="appModal__addBalance"
                  :mini="true"
                  :color="verifyBalance"
                >
                  Pay
                </AppButton>
              </div>
            </AppForm>
          </div>
        </div>
      </AppModal> -->

      <AppModal
        :is-open="card.succes"
        @close="closeSuccesModal"
      />
    </div>
    <div class="addresses">
      <!-- <AppProfileAdress
        v-for="(address, index) in this.$store.state.guardianAddresses"
        :key="address.id"
        :type="address.type"
        :adress="
          address.number +
            ', ' +
            address.street +
            ', ' +
            address.town +
            ', ' +
            address.country +
            ', ' +
            address.post_code
        "
        @delete="deleteAddress(address.id)"
        @edit="openEditModalAddress(index)"
        class="address"
      /> -->
      <AppModal
        :is-open="modalAddress"
        @close="closeModalAddress"
      >
        <AppForm
          title="Add an address"
          description="Enter your address details"
          :message="minError"
          @submit="addAddress"
        >
          <div class="addressTypeHolder">
            <select
              v-model="address.type"
              class="addressTypeSelect"
              @change="validateAddress"
            >
              <option
                selected="selected"
                value="0"
                disabled
              >
                Select your address type
              </option>
              <option>Home</option>
              <option>Work</option>
              <option>Grandma</option>
              <option>Grandpa</option>
              <option>Other</option>
            </select>
          </div>

          <AppInput
            v-model="address.street"
            placeholder="Street name"
            @input="validateAddress"
          />

          <AppInput
            v-model="address.town"
            placeholder="Town name"
            @input="validateAddress"
          />

          <AppInput
            v-model="address.number"
            placeholder="Street number"
            @input="validateAddress"
          />

          <AppInput
            v-model="address.postcode"
            placeholder="Street postcode"
            @input="validateAddress"
          />

          <AppInput
            v-model="address.country"
            placeholder="Country name"
            @input="validateAddress"
          />
          <!-- <AppButton
                                      class="appForm__button"
                                      :mini="true"
                                      :color="validateAddress"
                              > -->
          <AppButton
            class="appForm__button"
            :mini="true"
            :color="buttonColor"
          >
            Add address
          </AppButton>
          <div
            ref="msgContainer"
            class="content scrollable"
          >
            <!-- content -->
          </div>
        </AppForm>
      </AppModal>

      <AppModal
        :is-open="editAddressModal"
        @close="closeEditModalAddress"
      >
        <AppForm
          title="Edit child"
          description="Edit your child’s details"
          :message="minError"
          @submit="editAddressAction"
        >
          <div class="addressTypeHolder">
            <select
              v-model="editAddress.type"
              class="addressTypeSelect"
              @change="validateEditAddress"
            >
              <option
                selected="selected"
                value="0"
                disabled
              >
                Select your address type
              </option>
              <option>Home</option>
              <option>Work</option>
              <option>Grandma</option>
              <option>Grandpa</option>
              <option>Other</option>
            </select>
          </div>

          <AppInput
            v-model="editAddress.street"
            placeholder="Street name"
            @input="validateEditAddress"
          />

          <AppInput
            v-model="editAddress.town"
            placeholder="Town name"
            @input="validateEditAddress"
          />

          <AppInput
            v-model="editAddress.number"
            placeholder="Street number"
            @input="validateEditAddress"
          />
          <AppInput
            v-model="editAddress.postcode"
            placeholder="Street postcode"
            @input="validateEditAddress"
          />

          <AppInput
            v-model="editAddress.country"
            placeholder="Country name"
            @input="validateEditAddress"
          />

          <AppButton
            class="appForm__button"
            :mini="true"
            :color="editButtonColor"
          >
            Save
          </AppButton>
        </AppForm>
      </AppModal>
    </div>
    <!-- <div class="next__Button"> -->
    <!-- <SetupCancel class="setupMain__cancel" /> -->
    <!-- <RouterLink v-if="next" to="/dashboard"> -->
    <!-- <AppButton
        v-if="next"
        color="red"
        class="setupMain__button"
        @click="finishWizard"
      >
        Start your Sonik journey!
      </AppButton> -->
    <!-- </RouterLink> -->
    <!-- <AppButton
        v-else
        class="setupMain__button"
      >
        Start your Sonik journey!
      </AppButton> -->
    <!-- <SetupCancel class="setupMain__cancel" /> -->
    <!-- </div> -->
  </div>
</template>
<script>
// import SetupCancel from "@/components/SetupCancel";
import AddBalance from "../../components/AddBalance";
import AppForm from "../../components/AppForm";
import AppModal from "../../components/AppModal";
import AppModalPayment from "../../components/AppModalPayment";
import AppFormPayment from "../../components/AppFormPayment";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { mapActions } from "vuex";
import axios from "axios";

// import Card from '@/components/Card';
// import MissionChild from '@/components/MissionChild';


export default {
  components: {
    // SetupCancel,
    AddBalance,
    AppForm,
    AppModal,
    AppInput,
    AppButton,
    AppModalPayment,
    AppFormPayment,
    // Card,
    // MissionChild,
  },
  props: {
    next: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentCardBackground: Math.floor(Math.random() * 25 + 1),
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      amount: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,

      modal: false,
      editModal: false,
      modalPayment: false,
      balanceModal: false,
      address: {
        street: "",
        town: "",
        number: "",
        postcode: "",
        country: "",
        type: "0",
      },
      editAddress: {
        id: 0,
        street: "",
        town: "",
        number: "",
        postcode: "",
        country: "",
        type: "0",
      },
      correctAddress: "",
      blue: "",
      euro: 0,
      euroTotal: 0,
      red: "",
      minError: "",
      activeChild: 0,
      card: {
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        name: "",
        month: "",
        year: "",
        ccv: "",
        euro: "",
        succes: false,
        succesType: "",
      },
      modalAddress: false,
      editAddressModal: false,
      buttonColor: "",
      editButtonColor: "blue",
    };
  },
  // computed: {
  //   verifyAddress() {
  //     if (
  //       this.address.street.length != 0 &&
  //       this.address.town.length != 0 &&
  //       this.address.number.length != 0 &&
  //       this.address.postcode.length != 0 &&
  //       this.address.country.length != 0 &&
  //       this.address.type.length != 0
  //     )
  //       return "blue";
  //     else return "";
  //   },

  //   readyToPay() {
  //     if (this.$store.state.readyToPay) {
  //       return "blue";
  //     } else {
  //       return "";
  //     }
  //   },
  //   verifyBalance() {

  //     if (
  //       (this.card.euro >= 30 &&
  //         this.$store.state.balance == 0 &&
  //         this.card.code1 > 0 &&
  //         this.card.code2 > 0 &&
  //         this.card.code3 > 0 &&
  //         this.card.code4 > 0 &&
  //         this.card.name.length > 4 &&
  //         this.card.ccv.length == 3) ||
  //       (this.card.euro > 0 &&
  //         this.$store.state.balance > 0 &&
  //         this.card.code1 > 0 &&
  //         this.card.code2 > 0 &&
  //         this.card.code3 > 0 &&
  //         this.card.code4 > 0 &&
  //         this.card.name.length > 4 &&
  //         this.card.ccv.length == 3)
  //     )
  //       return "red";
  //     else return "";
  //   },
  //   next() {
  //     if ($store.state.balance) {
  //       return true;
  //     }
  //   },
  //   next() {
  //     if ($store.state.balance) {
  //       return true;
  //     }
  //   },
  // },
  created() {
    this.fetchProfile();
    this.fetchBalance();

    axios.interceptors.request.use(
      (config) => {
        this.isLoading = true;
        return config;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.isLoading = false;
        return response;
      },
      (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      }
    );

    this.activeChild = localStorage.getItem("guardian_setup_active_child_id");
  },
  methods: {
    ...mapActions([
      "fetchBalance",
      "finishWizardPage",
      "fetchProfile",
      "editGuardianAddress",
      "addGuardianAddressAction",
      "deleteGuardianAddressAction",
    ]),
    submitPayment() {
    },
    finishWizard() {
      this.finishWizardPage();
    },
    openModal() {
      this.modal = true;
    },
    openEditModal() {
      this.editModal = true;
    },
    openBalanceModal() {
      this.$store.state.balanceModal = true;
    },
    closeBalanceModal() {
      this.cardName = "";
      this.cardNumber = "";
      this.cardMonth = "";
      this.cardYear = "";
      this.cardCvv = "";
      this.amount = "";
      this.$store.state.balanceModal = false;
    },
    openSuccesModal() {
      this.balanceModal = false;
      this.card.succes = true;
    },
    closeSuccesModal() {
      this.card.succes = false;
    },
    closeModal() {
      this.modal = false;
      this.address.street = "";
      this.address.town = "";
      this.address.postcode = "";
      this.address.number = "";
    },
    addAdress() {
      this.correctAddress = `${this.address.town}, ${this.address.street} ${this.address.number}, ${this.address.postcode}`;
      this.modal = false;
    },
    addMoney() {
      if (this.euro <= 30 && this.$store.state.balance == 0)
        this.minError = "£30.00 minimum needed";
      else {
        this.minError = "";
        this.$store.dispatch("addMoney", Number(this.euro));
        this.euro = 0;
        this.balanceModal = false;
      }
    },

    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    childActivate(id) {
      this.activeChild = id;
    },
    validateNextStep() {
      if (this.$store.state.balance) {
        this.$emit("next");
      } else {
        this.$emit("no-next");
      }
    },
    closeModalPayment() {
      this.modalPayment = false;
      this.$store.state.paymentSuccess = false;
    },
    openModalAddress() {
      this.modalAddress = true;
    },
    closeModalAddress() {
      this.modalAddress = false;
    },
    addAddress() {
      if (this.buttonColor != "") {
        const addressStreet = this.address.street;
        const addressTown = this.address.town;
        const addressNumber = this.address.number;
        const addressPostcode = this.address.postcode;
        const addressCountry = this.address.country;
        const addressType = this.address.type;

        if (
          addressStreet &&
          addressTown &&
          addressNumber &&
          addressPostcode &&
          addressCountry &&
          addressType
        ) {
          const formData = {
            addressStreet,
            addressTown,
            addressNumber,
            addressPostcode,
            addressCountry,
            addressType,
          };

          this.addGuardianAddressAction({ formData });
          this.closeModalAddress();
        }
      } else {
        this.minError = "Please select your address type!";
      }
    },
    deleteAddress(id) {
      var x = confirm("Are you sure you want to delete this address?");
      if (x) this.deleteGuardianAddressAction(id);
      else return false;
    },
    closeEditModalAddress() {
      this.editAddressModal = false;
    },
    openEditModalAddress(index) {
      const address = this.$store.state.guardianAddresses[index];

      this.editAddressModal = true;

      this.editAddress.id = address.id;
      this.editAddress.street = address.street;
      this.editAddress.town = address.town;
      this.editAddress.number = address.number;
      this.editAddress.postcode = address.post_code;
      this.editAddress.country = address.country;
      this.editAddress.type = address.type;
    },
    editAddressAction() {
      if (this.editButtonColor != "") {
        this.editGuardianAddress(this.editAddress);
        this.closeEditModalAddress();
        this.editAddress = {
          street: "",
          town: "",
          number: "",
          postcode: "",
          country: "",
          type: "",
        };
      } else {
        this.minError = "Please select your address type!";
      }
    },
    validateAddress() {
      if (
        this.address.street.length != 0 &&
        this.address.town.length != 0 &&
        this.address.number.length != 0 &&
        this.address.postcode.length != 0 &&
        this.address.country.length != 0 &&
        this.address.type != 0
      ) {
        this.buttonColor = "red";
      } else {
        this.buttonColor = "";
      }
    },
    validateEditAddress() {
      if (
        this.editAddress.street.length != 0 &&
        this.editAddress.town.length != 0 &&
        this.editAddress.number.length != 0 &&
        this.editAddress.postcode.length != 0 &&
        this.editAddress.country.length != 0 &&
        this.editAddress.type != 0
      ) {
        this.editButtonColor = "blue";
      } else {
        this.editButtonColor = "";
      }
    },
  },
  computed: {
    readyToPay() {
      if (this.$store.state.readyToPay) {
        return "blue";
      } else {
        return "";
      }
    },
    verifyBalance() {
      //                if (
      //                    this.euro >= 30 && this.$store.state.balance == 0
      //                    || this.euro > 0 && this.$store.state.balance > 0
      //                ) return "red";
      //                else return "";

      if (
        this.cardNumber.length != 0 &&
        this.cardName.length != 0 &&
        this.cardMonth.length != 0 &&
        this.cardYear.length != 0 &&
        this.cardCvv.length != 0 &&
        this.amount.length != 0
      ) {
        return "blue";
      } else {
        return "";
      }
    },
    reservedRewards(funds) {
      return this.sum + funds;
    },

    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^9792");
      if (number.match(re) != null) return "troy";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    },
    "$store.state.paymentSuccess"() {
      //alert(this.$store.state.paymentSuccess);
      if (this.$store.state.paymentSuccess === true) {
        this.modalPayment = true;
      }
    },
  },
  mounted() {
    this.fetchBalance();
    this.cardNumberTemp = this.otherCardMask;
    document.getElementById("cardNumber").focus();
    document
      .getElementById("cardNumber")
      .addEventListener("input", function (e) {
        e.target.value = e.target.value
          .replace(/[^\dA-Z]/g, "")
          .replace(/(.{4})/g, "$1 ")
          .trim();
      });
    if (this.$store.state.setup.money == null) {
      this.$emit("no-next");
    } else {
      this.$emit("next");
    }
    if (this.$store.state.setup.address == null) {
      this.$emit("no-next");
    } else {
      this.$emit("next");
    }
    window.setInterval(() => {
      this.validateNextStep();
    }, 2000);
  },
  // beforeMount() {
  // 	if (this.correctAddress && this.$store.state.balance) {
  // 		this.$emit('next');
  // 	} else {
  // 		this.$emit('no-next');
  // 	}
  // },
  // watch: {
  // 	'correctAddress'() {
  // 		if (this.correctAddress && this.$store.state.balance) {
  // 			this.$emit('next');
  // 		} else {
  // 			this.$emit('no-next');
  // 		}
  // 	},
  // 	'$store.state.balance'() {
  // 		if (this.correctAddress && this.$store.state.balance) {
  // 			this.$emit('next');
  // 		} else {
  // 			this.$emit('no-next');
  // 		}
  // 	}
  // }
};
</script>

<style lang="scss" scoped>
.addresses {
  width: 100%;
}

.setupMain__boxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  width: 520px;
  // position: relative;
  // bottom: 30px;
}

.AddBalance {
  // margin-left: 50px;
  width: 100%;
}

.addBalance__box {
  margin-top: 50px;
  height: 300px;
}

#wizardBalance {
  width: 100% !important;
}

.appModal__address__small {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.appModal__address__small > input {
  width: 180px;
}

.appModal__verify {
  margin-top: 50px;
  
  font-style: normal;
  font-weight: normal;
  color: #36d5f2;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.moneyFrame {
  width: 275px;
  height: 178px;
}

.cardCode {
  display: flex;
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.cardCode > input {
  margin-left: 15px;
  width: 120px;
  margin-right: 9px;
}

.cardCode > input::-webkit-input-placeholder {
  text-align: center;
}

.cardCode > input:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.cardCode > input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.cardCode > input:-ms-input-placeholder {
  text-align: center;
}

.moneyCardCCV {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  margin-right: 20px;
}

.moneyCardCCV > input {
  width: 150px;
  margin-left: 35px;
}

// .appModal__addAdress{
// 	padding: 50px !important;
// }

// .appInput::-webkit-outer-spin-button,
// .appInput::-webkit-inner-spin-button{
// 	-webkit-appearance: none;
// 	margin: 0;
// }
// .appInput[type=number] {
// 	-moz-appearance:textfield;
// }

.setupMain__title {
  width: 100%;
  margin-bottom: 20px;
  font-size: 25px;
  text-align: center;
}

@media screen and (max-width: 950px) {
  .setupMain__boxes {
    flex-direction: column;
    width: 100%;
  }

  .AddBalance {
    margin-left: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.next__Button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
}

@media screen and (max-width: 600px) {
  .appModal__address__small {
    flex-direction: column;
  }

  .appModal__address__small > input {
    width: 100%;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
  // font-family: Roboto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: transparent;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    //box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    //padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: 50px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 70px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    padding: 5px 25px;
    background: none;
    color: #7a615a;
    border: 1px solid #e5e5e5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 74px;
    outline: none;
    // font-family: Roboto;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}

.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}

.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

@media only screen and (max-width: 820px) {
  .next__Button {
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
  }
  .setupMain__button {
    min-height: 45px;
  }
  .appButton {
    height: 20px;
    margin-top: 10px;
    // margin-bottom: 10px !important;
  }
  .setupMain__title {
    font-size: 20px;
    text-align: center;
    padding: 0 20px;
    margin-top: 20px;
  }
  .addBalance {
    order: 1;
  }
  .addAddress {
    order: 2;
  }
  .addresses {
    order: 3;
    display: block;
    margin-bottom: 20px;
  }
  .setupMain__content {
    display: block;
  }
  .AddBalance {
    margin: 0;
  }
  .addBalance__box__active {
    border-radius: 20px;
  }
}
.wrapper {
  overflow: scroll;
  height: 65%;
  padding-top: 0 !important;
}

@media only screen and (max-width: 820px) {
  .wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    padding-top: 0 !important;
  }
  .card-list {
    display: none;
  }
  .appForm__description {
    margin: 0;
  }
  .card-input {
    margin-bottom: 10px;
  }
  .wrapper .appInput {
    margin-top: 0;
  }

  .addBalance__box__title__active {
    margin: 0;
  }

  .setupMain__boxes .addBalance__box__money__active {
    margin: 0 !important;
  }
}


</style>

<template>
  <form
    class="appForm"
    @submit.prevent="$emit('submit')"
  >
    <div style="margin-bottom: 60px;">
      <img src="../assets/images/check.svg">
    </div>
    <div class="appForm__title">
      {{ title }}
    </div>
    <div class="appForm__description">
      {{ description }}
    </div>
    <div class="appForm__thanks">
      Thank you!
    </div>
    <div class="appForm__content">
      <slot />
    </div>
    <div
      v-if="message"
      class="appForm__message"
      @click="$emit(msgEvent)"
    >
      {{ message }}
    </div>
  </form>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            message: {
                type: String,
                default: ''
            },
            msgEvent: {
                type: String,
                default: ''
            }
        }
    };
</script>

<style lang="scss">
    .appForm {
        color: #7A615A;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__title {
            text-align: center;
            position: relative;
            font: {
                size: 55px;
            }
        }

        &__description {
            position: relative;
            text-align: center;
            margin-top: -20px;
            font: {
                size: 18px;
            }
        }

        &__thanks {
            position: relative;
            text-align: center;
            font: {
                size: 18px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__message {
            margin-top: 30px;
            cursor: pointer;
            color: #FF4F79;
            font: {
                size: 18px;
            }
        }
    }

    @media screen and (max-width: 850px) {
        .appForm {
            &__title {
                font: {
                    size: 45px;
                }
            }
        }
    }

    /*@media screen and (max-width: 850px) {*/
    /*	.appForm {*/
    /*		&__title {*/
    /*			top: -80px;*/
    /*		}*/
    /**/
    /*		&__description {*/
    /*			top: -70px;*/
    /*		}*/
    /**/
    /*		&__content {*/
    /*			position: relative;*/
    /*			top: -60px;*/
    /*		}*/
    /*}*/
    /*}*/

    @media screen and (max-height: 650px) {
        .appForm {
            &__title {
                top: -120px;
            }

            &__description {
                top: -100px;
            }

            &__content {
                position: relative;
                top: -20px;
            }
        }
    }

    @media screen and (max-height: 750px) {
        .appForm {
            &__title {
                top: -40px;
            }

            &__description {
                top: -20px;
            }
        }
    }
</style>
